import UserService from '../../services/server/UserService';
import ShoutoutService from '../../services/server/ShoutoutService';
import Server from '../../server/Server';
import UserPage from '../../components/pages/UserPage';
import Links from '../../navigation/Links';
import ServerUtil from '../../server/ServerUtil';
import Meta from '../../util/Meta';

function UserProfilePage({ user, shoutouts, stats }) {
	return (
		<UserPage
			meta={{ title: Meta.Title(user.name || user.username) }}
			user={user}
			shoutouts={shoutouts}
			stats={stats}
		/>
	);
}

export default UserProfilePage;

/** Get by authenticated user or public user */
export const getServerSideProps = async ({ req, res, params, user, query }) => {
	const { username } = params || {};

	user = user || (await ServerUtil.getUser(req));
	if (query?.email === 'true' && !user) {
		return Server.redirect(Links.Login(ServerUtil.parseUrl(req.url)));
	}

	const isPersonalProfile = username ? user?.username === username : !!user;
	let rUser;
	try {
		rUser = await UserService.getByUsername(
			isPersonalProfile ? user.username : username
		);
	} catch (e) {
		return Server.error(res, e);
	}

	return Promise.all([
		ShoutoutService.getUserShoutouts(rUser.id, user?.id),
		UserService.getStats(rUser.id, { company: rUser.company }),
	])
		.then(([shoutouts, stats]) => ({
			props: {
				user: rUser,
				shoutouts:
					{
						given: shoutouts?.given,
						received: shoutouts?.received,
						hidden: {
							count: shoutouts?.hidden?.count || 0,
						},
					} || [],
				stats,
			},
		}))
		.catch((e) => Server.error(res, e));
};
